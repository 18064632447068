import { useMemo } from "react";
import {
  Box,
  Container,
  Grid,
  Heading,
  HStack,
  Icon,
  List,
  ListItem,
  Spacer,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import Logo from "../Logo";
import UiLink from "../UiLink";
import useTranslation from "next-translate/useTranslation";

type FooterLink = {
  text: string;
  href: string;
};

type FooterColumn = {
  header: string;
  links: Array<FooterLink>;
};

const Footer = (): JSX.Element => {
  const { t } = useTranslation("footer");

  const footerLinks: Array<FooterColumn> = useMemo(
    () => [
      {
        header: t("company"),
        links: [
          {
            text: t("our-team"),
            href: "/about/team",
          },
          // {
          //   text: t('careers),
          //   href: '/careers',
          // },
          // {
          //   text: t('partners),
          //   href: '/partners',
          // },
          {
            text: t("press"),
            href: "/press",
          },
          {
            text: t("resources"),
            href: "https://learn.farmhub.ag/resources",
          },
          {
            text: t("blog"),
            href: "https://learn.farmhub.ag/articles",
          },
          {
            text: t("referral-program"),
            href: "https://affiliates.farmhub.ag",
          },
          {
            text: t("privacy-policy"),
            href: "/legal/privacy-policy",
          },
          {
            text: t("terms-of-service"),
            href: "/legal/terms-of-service",
          },
        ],
      },
      {
        header: t("solutions"),
        links: [
          {
            text: t("commercial-and-research"),
            href: "/clients/commercial",
          },
          {
            text: t("common:backyard-diy"),
            href: "/clients/backyard",
          },
          {
            text: t("common:stem-educators"),
            href: "/clients/stem",
          },
          {
            text: t("common:pricing"),
            href: "/pricing",
          },
        ],
      },
      {
        header: t("contact"),
        links: [
          {
            text: t("common:support"),
            href: "https://docs.farmhub.ag",
          },
          {
            text: t("sales"),
            href: "mailto:hello@farmhub.ag",
          },
          {
            text: t("system-status"),
            href: "https://status.farmhub.ag",
          },
        ],
      },
    ],
    [t]
  );

  return (
    <Box bg="brandDark.900">
      <Container maxW="4xl" pt={20} pb={16}>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
          gap={6}
        >
          {footerLinks.map(({ header, links }) => (
            <Box key={header}>
              <Heading mb={6} size="sm" color="white" fontWeight="light">
                {header}
              </Heading>
              <List spacing={1}>
                {links.map(({ text, href }) => (
                  <ListItem key={href}>
                    <UiLink
                      color="gray.500"
                      href={href}
                      fontWeight="light"
                      _hover={{ color: "white" }}
                    >
                      {text}
                    </UiLink>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Grid>
      </Container>
      <Container maxW="6xl">
        <HStack
          as="footer"
          justifyContent="center"
          borderTop="1px solid"
          borderColor="gray.700"
          p={6}
          wrap="wrap"
          textAlign={{ base: "center", md: "inherit" }}
        >
          <Icon h={12} w={12} as={Logo} color="brand.900" />
          <Text fontSize="md" color="gray.300" mt={5}>
            {t("copyright-2019")}
          </Text>
          <Spacer display={{ base: "none", md: "flex" }} />
          <UiLink
            href="https://facebook.com/farmhub.ag"
            rel="me noopener"
            aria-label="Facebook"
            isExternal={true}
          >
            <Icon as={FaFacebook} color="gray.300" />
          </UiLink>
          <UiLink
            rel="me noopener"
            aria-label="Youtube"
            href="https://www.youtube.com/@thefarmhub"
            isExternal={true}
          >
            <Icon as={FaYoutube} color="gray.300" />
          </UiLink>
          <UiLink
            rel="me noopener"
            aria-label="Instagram"
            href="https://www.instagram.com/farmhub.ag/"
            isExternal={true}
          >
            <Icon as={FaInstagram} color="gray.300" />
          </UiLink>
        </HStack>
      </Container>
    </Box>
  );
};

export default Footer;
