import React from 'react';
import { PropsWithChildren } from 'react';
import NextLink from 'next/link';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

export type UiLinkProps = PropsWithChildren<NextLinkProps> & ChakraLinkProps;

//  Has to be a new component because both chakra and next share the `as` keyword
export const UiLink = ({
  href,
  replace,
  scroll,
  shallow,
  prefetch,
  children,
  ...chakraLinkProps
}: UiLinkProps): JSX.Element => {
  return (
    <NextLink
      passHref={true}
      href={href}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      prefetch={prefetch}
    >
      <ChakraLink _hover={{}} {...chakraLinkProps}>
        {children}
      </ChakraLink>
    </NextLink>
  );
};

export default UiLink;
