// The main domain of the site without a trailing slash
export const SITE_URL = process.env.SITE_URL || "https://farmhub.ag";

export const GRAPHQL_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || "https://api.farmhub.ag/graphql";

// ID for: https://www.facebook.com/aquaponicsai
export const FB_PAGE_ID = "397713537695224";

export const POSTHOG_KEY =
  process.env.NEXT_PUBLIC_POSTHOG_KEY || "not-available";

export const POSTHOG_HOST =
  process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com";

export const GOOGLE_TAG_MANAGER_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || "not-available";

export const CUSTOMER_IO_SITE_ID =
  process.env.NEXT_PUBLIC_CUSTOMER_IO_SITE_ID || "not-available";

export const CUSTOMER_IO_API_KEY =
  process.env.NEXT_PUBLIC_CUSTOMER_IO_API_KEY || "not-available";

export const STRIPE_PUBLISHABLE_KEY: string =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ||
  "pk_test_hP2i4k8T5jOpp6MM298FK5ma00I5Lyogj6";

export const MIXPANEL_TOKEN =
  process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "not-available";

export const LANGUAGES: Array<{ label: string; key: string }> = [
  {
    label: "English",
    key: "en",
  },
  {
    label: "Español",
    key: "es",
  },
  {
    label: "عربى",
    key: "ar",
  },
  {
    label: "Français",
    key: "fr",
  },
  {
    label: "Português",
    key: "pt",
  },
  {
    label: "Italiano",
    key: "it",
  },
  {
    label: "हिन्दी",
    key: "hi",
  },
  {
    label: "Kiswahili",
    key: "sw",
  },
  {
    label: "中文",
    key: "zh",
  },
  {
    label: "русский",
    key: "ru",
  },
];
