import { Avatar } from '@chakra-ui/avatar';
import {
  Heading,
  HStack,
  BoxProps,
  Spacer,
  Text,
  VStack,
  Link,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/layout';
import NextLink from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import Crop from '../../models/crop';
import Fish from '../../models/fish';
import { LabTest } from '../../models/lab-test';
import Template from '../../models/template';

type ProductCardProps = {
  href?: string;
  product?: Template | Crop | Fish | LabTest;
};

const ProductCard = ({
  product,
  href,
  ...props
}: ProductCardProps & BoxProps): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <LinkBox
      display="flex"
      as="article"
      bg="white"
      rounded="md"
      borderWidth={1}
      borderColor="gray.200"
      boxShadow="sm"
      cursor="pointer"
      _hover={{ boxShadow: 'md' }}
      p={6}
      w="full"
      {...props}
    >
      <VStack align="stretch" w="full">
        <Heading noOfLines={2} size="md" fontWeight="bold">
          <LinkOverlay as={NextLink} href={href as string}>
            {product?.name}
          </LinkOverlay>
        </Heading>
        <Text fontSize="sm" noOfLines={2}>
          {product?.productDescription}
        </Text>
        <Spacer />
        <HStack>
          <Avatar size="xs" src={product?.merchant?.logo?.url} />
          <Link
            as={NextLink}
            href={`/marketplace/-/${product?.merchant?.slug}`}
            _hover={{ textDecor: 'underline' }}
          >
            <Text fontSize="sm" noOfLines={1}>
              {product?.merchant?.name}
            </Text>
          </Link>

          <Spacer />
          <Text fontWeight="bold">
            {product?.price || 0 > 0
              ? (product?.price || 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
              : t('free')}
          </Text>
        </HStack>
      </VStack>
    </LinkBox>
  );
};

export default ProductCard;
