import { Avatar } from '@chakra-ui/avatar';
import Icon from '@chakra-ui/icon';
import {
  Box,
  Stack,
  Container,
  VStack,
  Heading,
  HStack,
  Link,
  As,
} from '@chakra-ui/react';
import React from 'react';
import {
  FaFacebook,
  FaGlobeAfrica,
  FaLinkedin,
  FaTwitter,
} from 'react-icons/fa';
import MerchantModel from '../../models/merchant';

type MerchantHeaderProps = {
  data: MerchantModel;
  as?: As<any>;
};

const MerchantHeader = ({ as, data }: MerchantHeaderProps): JSX.Element => (
  <Box
    p={10}
    pt={{ base: 20, md: 300 }}
    bg="no-repeat center center"
    bgSize="cover"
    bgImage={`linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)),url(${data.coverPhoto?.url})`}
  >
    <Container maxW="6xl">
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={6}
        alignItems="center"
      >
        <Avatar size="xl" shadow="md" borderWidth={6} src={data.logo?.url} />
        <VStack alignItems="start">
          <Heading color="white" textShadow="md" as={as || 'h2'}>
            <Link href={`/marketplace/-/${data.slug}`}>{data.name}</Link>
          </Heading>
          <HStack>
            {data.websiteUrl && (
              <Link href={data.websiteUrl} isExternal={true}>
                <Icon as={FaGlobeAfrica} color="white" />
              </Link>
            )}
            {data.twitterUrl && (
              <Link href={data.twitterUrl} isExternal={true}>
                <Icon as={FaTwitter} color="white" />
              </Link>
            )}
            {data.facebookUrl && (
              <Link href={data.facebookUrl} isExternal={true}>
                <Icon as={FaFacebook} color="white" />
              </Link>
            )}
            {data.linkedinUrl && (
              <Link href={data.linkedinUrl} isExternal={true}>
                <Icon as={FaLinkedin} color="white" />
              </Link>
            )}
          </HStack>
        </VStack>
      </Stack>
    </Container>
  </Box>
);

export default MerchantHeader;
