import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

type HtmlContentProps = {
  header: string;
  html: string;
};

const HtmlContent = ({
  header,
  html,
}: Partial<HtmlContentProps>): JSX.Element => {
  if (!html) return <></>;

  return (
    <Box>
      {header && (
        <Heading
          alignSelf="center"
          as="h2"
          fontWeight="semibold"
          size="lg"
          mb={4}
        >
          {header}
        </Heading>
      )}

      <Box className="styled" dangerouslySetInnerHTML={{ __html: html }} />
    </Box>
  );
};

export default HtmlContent;
