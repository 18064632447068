import { Text, TextProps } from '@chakra-ui/layout';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

type PriceProps = {
  amount: number;
};

const Price = ({ amount, ...props }: PriceProps & TextProps): JSX.Element => {
  const { t } = useTranslation('common');

  const formatted = amount.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Text {...props}>
      {amount === 0 && t('free').toString().toUpperCase()}
      {amount !== 0 && formatted}
    </Text>
  );
};

export default Price;
