import Cookies from 'js-cookie';
import jwt from 'jwt-decode';

const AUTH_COOKIE_NAME = 'aai-auth';

/**
 * Saves the token to the store
 * @param {string} token JWT token from the server
 */
export const setToken = (token: string): void => {
  Cookies.set(AUTH_COOKIE_NAME, token, { secure: true });
};

/**
 * Parses the jwt from cookies and removes if expired
 * @return {string} JWT token
 */
export const getToken = (): string | undefined => {
  const now = Date.now().valueOf() / 1000;

  const token: string | undefined = Cookies.get(AUTH_COOKIE_NAME);
  if (!token) return;

  const parsed: any = jwt(token);

  // Remove and nullify if expired
  if (parsed.exp < now) {
    removeToken();
    return;
  }

  return token;
};

export const removeToken = (): void => {
  Cookies.remove(AUTH_COOKIE_NAME);
};

export const getAuthorzationHeader = (): string | undefined => {
  const token = getToken();
  return token ? `Bearer ${token}` : undefined;
};

export const isAuthenticated = (): boolean => {
  return !!getToken();
};
