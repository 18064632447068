import React, { useState, useEffect } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import Product from '../../models/product';

export const LIST_PRODUCTS = gql`
  query ListProducts($first: Int, $after: String, $tags: [String!]) {
    store {
      products(tags: $tags, first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          id
          name
          description
          image {
            url(width: 500, height: 500)
          }
          price
          tagList
        }
      }
    }
  }
`;

interface QueryCallbackProps {
  loading: boolean;
  error: ApolloError | undefined;
  products: Product[];
}

type QueryProductsProps = {
  tags?: string[];
  children: (props: QueryCallbackProps) => React.ReactNode;
};

const QueryProducts = ({ tags, children }: QueryProductsProps): JSX.Element => {
  const [products, setProducts] = useState<Product[]>([]);
  const { data, loading, error } = useQuery(LIST_PRODUCTS, {
    variables: {
      tags,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(
    () => setProducts((data?.store?.products?.nodes || []) as Product[]),
    [data]
  );

  return <>{children({ loading, error, products })}</>;
};

export default QueryProducts;
