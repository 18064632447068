import React from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtl from 'stylis-plugin-rtl';
import useTranslation from 'next-translate/useTranslation';

const options: any = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' },
};

export default function RtlProvider({
  children,
}: {
  children: any;
}): JSX.Element {
  const { lang } = useTranslation();

  const dir = lang == 'ar' ? 'rtl' : 'ltr';
  const cache = createCache(options[dir]);

  return <CacheProvider value={cache}>{children}</CacheProvider>;
}
