import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  chakra,
  HStack,
  HTMLChakraProps,
  Icon,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion, AnimatePresence, HTMLMotionProps } from "framer-motion";
import { FaFireAlt, FaTimes } from "react-icons/fa";
import fetch from "node-fetch";
import useTranslation from "next-translate/useTranslation";
import moment from "moment";
import ReactMarkdown from "react-markdown";

type Merge<P, T> = Omit<P, keyof T> & T;
type MotionBoxProps = Merge<HTMLChakraProps<"div">, HTMLMotionProps<"div">>;
export const MotionBox: React.FC<MotionBoxProps> = motion(chakra.div);

const PREROLL_DURATION = 4000;
const SHOW_DURATION = 5000;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SocialProof = (): JSX.Element => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [activity, setActivity] = useState<Array<any>>([]);
  const [activeItem, setActiveItem] = useState<any>(null);

  const showRandomItem = useCallback(async () => {
    const randomIdx = Math.floor(Math.random() * activity.length);
    await setActiveItem(activity[randomIdx]);
  }, [activity]);

  useEffect(() => {
    const run = async () => {
      const res = await fetch("https://api.farmhub.ag/feed/public-activity");
      const data = await res.json();

      await setActivity(data.activity);
    };

    run();
  }, []);

  useEffect(() => {
    const snoozedDate = localStorage?.getItem("aai-public-activity");

    if (activity.length === 0 || moment(snoozedDate).isAfter(moment.now())) {
      setIsOpen(false);
      return;
    }

    const run = async () => {
      showRandomItem();
      await sleep(PREROLL_DURATION);
      await setIsOpen(true);
      await sleep(SHOW_DURATION);
      await setIsOpen(false);
    };

    run();

    const interval = setInterval(run, PREROLL_DURATION + SHOW_DURATION + 100);
    return () => clearInterval(interval);
  }, [activity, showRandomItem]);

  const close = useCallback(() => {
    // Expires a day from now to continue social proof
    localStorage?.setItem(
      "aai-public-activity",
      moment().add(1, "day").toISOString()
    );

    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <AnimatePresence>
      {isOpen && activeItem && (
        <MotionBox
          display={{ base: "none", md: "block" }}
          position="fixed"
          zIndex={1000}
          bottom={4}
          left={4}
          transition={{ duration: 0.7 }}
          initial={{ y: 200, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 200, opacity: 0 }}
        >
          <HStack
            rounded="full"
            shadow="lg"
            width={400}
            bg="white"
            p={1}
            px={4}
          >
            <Icon as={FaFireAlt} color="brand.900" fontSize="4xl" m={2} />
            <VStack spacing={0} alignItems="start" flex={1}>
              <Box flex={1} as={ReactMarkdown} fontSize="sm">
                {t(`components.social-proof.${activeItem.action}`, {
                  ...activeItem,
                  projectType: t(
                    `components.social-proof.project-type.${activeItem?.type}`
                  ),
                }).toString()}
              </Box>
              <Text fontSize="xs" color="gray.500">
                {moment(activeItem.timestamp).fromNow()}
              </Text>
            </VStack>
            <IconButton
              size="xs"
              onClick={close}
              rounded="full"
              aria-label="Close"
              variant="ghost"
              icon={<FaTimes />}
            />
          </HStack>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default SocialProof;
