import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import User from '../../models/user';
import { setToken, removeToken } from '../../lib/auth';
import { gql, useMutation } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import { isAuthenticated as authIsAuthenticated } from '../../lib/auth';

const LOGIN = gql`
  mutation LoginUser($email: String!, $password: String!, $source: String) {
    authLogin(email: $email, password: $password, source: $source) {
      token
      user {
        id
        name
        preferences {
          language
        }
      }
    }
  }
`;

type AuthContextProps = {
  user: User | undefined;
  login: (email: string, password: string, source?: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
};

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  logout: async () => undefined,
  login: async () => undefined,
  isAuthenticated: false,
});

export const useAuthContext = (): AuthContextProps => useContext(AuthContext);

function AuthContextProvider({ children }: any) {
  const { track, identify } = useAnalytics();
  const [user, setUser] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    authIsAuthenticated()
  );
  const [authLogin] = useMutation(LOGIN);

  const logout = useCallback(async () => {
    setUser(undefined);
    setIsAuthenticated(false);
    removeToken();
  }, [setUser]);

  const login = useCallback(
    async (email: string, password: string, source = 'www') => {
      const { data } = await authLogin({
        variables: {
          email,
          password,
          source,
        },
      });

      const user = data?.authLogin?.user;

      setUser(user);
      setToken(data?.authLogin?.token);
      identify(user?.id, { name: user?.name });
      setIsAuthenticated(true);
      track('Login', user);
    },
    [setUser, authLogin, identify, track]
  );

  const defaultContext: AuthContextProps = useMemo(() => {
    return {
      user,
      login,
      logout,
      isAuthenticated,
    };
  }, [user, login, logout, isAuthenticated]);

  return (
    <AuthContext.Provider value={defaultContext}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
