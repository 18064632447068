import React from "react";
import { Box, Heading, Text, Image } from "@chakra-ui/react";
import UiLink from "../UiLink";
import { StaticImageData } from "next/image";

type LibraryCardProps = {
  title: string;
  href: string;
  description: string;
  image: StaticImageData;
};

const LibraryCard = ({
  title,
  href,
  image,
  description,
}: Partial<LibraryCardProps>): JSX.Element => {
  const url = href || "#";

  return (
    <UiLink
      href={url}
      _hover={{ bg: "brand.900", color: "white" }}
      bg="gray.200"
      rounded="lg"
      overflow="hidden"
    >
      <Box textAlign="center">
        {image && <Image src={image?.src} alt={title} />}
        {title && (
          <Heading as="h3" size="md" py={2}>
            {title}
          </Heading>
        )}
        {description && <Text>{description}</Text>}
      </Box>
    </UiLink>
  );
};

export default LibraryCard;
