import theme from "@chakra-ui/theme";

const defaultTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      100: "#EDF6FF",
      200: "#DBEDFF",
      300: "#6DB6FF",
      400: "#5BADFF",
      500: "#49A4FF",
      600: "#379BFF",
      700: "#2492FF",
      800: "#1289FF",
      900: "#0280ff",
    },
    brandDark: {
      100: "#CCEFFF",
      200: "#99DBFF",
      300: "#67C1FF",
      400: "#41A9FF",
      500: "#0280FF",
      600: "#0163DB",
      700: "#0149B7",
      800: "#003393",
      900: "hsl(220,95%,8%)",
    },
    blue: {
      ...theme.colors.blue,
      900: "#031b4f",
    },
    grayCustom: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
  },
};

export default defaultTheme;
