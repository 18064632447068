import * as React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const LoadingScreen = (): JSX.Element => (
  <Flex flex={1} minH="50vh" alignItems="center" justifyContent="center">
    <Spinner size="xl" color="gray.400" />
  </Flex>
);

export default LoadingScreen;
