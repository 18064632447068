import * as React from 'react';
import { Link, HStack, Tag } from '@chakra-ui/react';
import { LANGUAGES } from '../../config';
import { PropsOf } from '@emotion/react';
import { makePath } from '../../lib/i18n';

type Props = {
  slug?: string;
};

const ContentLanguageSwitcher = ({
  slug,
  ...props
}: Props & PropsOf<typeof HStack>): JSX.Element => (
  <HStack spacing={1} wrap="wrap" {...props}>
    {LANGUAGES.map((language) => (
      <Link
        key={language.key}
        href={makePath(slug || '', language.key)}
        hrefLang={language.key}
        _hover={{ textDecoration: 'none' }}
      >
        <Tag
          size="sm"
          variant="solid"
          colorScheme="gray"
          _hover={{ color: 'white', bg: 'brand.900' }}
        >
          {language.label}
        </Tag>
      </Link>
    ))}
  </HStack>
);

export default ContentLanguageSwitcher;
