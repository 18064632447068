import React, { ReactNode } from "react";
import { Navbar, SocialProof } from "..";
import { Box, BoxProps } from "@chakra-ui/react";
import { FB_PAGE_ID, LANGUAGES } from "../../config";
import defaultSocial from "../../assets/images/social/default.jpg";
import Footer from "../Footer";
import { defaultTo } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { usePersistLocaleCookie, makeAlternatePath } from "../../lib/i18n";
import { DefaultSeo } from "next-seo";
import { StaticImageData } from "next/image";

type LayoutProps = {
  children?: ReactNode;
  title?: string;
  seoTitle?: string;
  seoDescription?: string;
  description?: string;
  image?: string | StaticImageData;
  permalink?: string;
  navTheme?: string;
  url?: string;
  ampUrl?: string;
};

const Layout = ({
  children,
  title = "FarmHub | Aquaponics made easy.",
  seoTitle: seoTitleAttr,
  seoDescription: seoDescriptionAttr,
  description,
  image = defaultSocial,
  permalink,
  navTheme,
  ampUrl,
  ...extra
}: LayoutProps & BoxProps): JSX.Element => {
  const { t } = useTranslation("common");
  usePersistLocaleCookie();

  const seoTitle = seoTitleAttr || title || t("default-layout-title");
  const seoDescription = seoDescriptionAttr || description;
  const imgSrc = typeof image === "string" ? image : image?.src;
  const links: any = [];
  const alternatives: any = [];

  if (ampUrl) {
    links.push({
      rel: "amphtml",
      href: ampUrl,
    });
  }

  if (permalink) {
    LANGUAGES.forEach((language) => {
      alternatives.push({
        hrefLang: language.key,
        href: makeAlternatePath(permalink, language.key),
      });
    });
  }

  return (
    <Box {...extra}>
      <DefaultSeo
        title={defaultTo(title, t("default-layout-title"))}
        titleTemplate={"%s | FarmHub"}
        description={description}
        canonical={permalink}
        openGraph={{
          title: seoTitle,
          description: seoDescription,
          images: [
            {
              url: imgSrc,
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
        }}
        languageAlternates={alternatives}
        additionalLinkTags={links}
        additionalMetaTags={[{ property: "fb:pages", content: FB_PAGE_ID }]}
      />

      <SocialProof />
      <Navbar />

      {children}

      <Footer />
    </Box>
  );
};

export default Layout;
