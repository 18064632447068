/* eslint-disable max-len */

import React, { ReactElement } from 'react';
import { Icon, PropsOf } from '@chakra-ui/react';

const Logo = (props: PropsOf<typeof Icon>): ReactElement => {
  return (
    <Icon viewBox="0 0 25 25" {...props}>
      <path
        fill="currentColor"
        d="M1.783,16.096c0-1.625,1.317-2.942,2.943-2.942
c0.216,0,0.426,0.023,0.628,0.067c1.582,0.23,1.998,1.626,2.44,2.934c0.196,0.581,0.511,1.118,1.138,1.294
c0.593,0.167,1.269,0.106,1.871,0.028c0.888-0.115,1.647-0.19,2.484,0.221c0.882,0.433,1.381,1.257,1.506,2.135
c0.023,0.148,0.035,0.299,0.035,0.453c0,0.318-0.05,0.624-0.144,0.91c-0.149,0.477-0.418,0.915-0.804,1.252
c-0.524,0.484-1.225,0.78-1.995,0.78c-0.72,0-1.424-0.268-1.96-0.748c-0.559-0.5-0.767-1.096-0.96-1.797
c-0.091-0.329-0.243-0.627-0.431-0.911c-0.384-0.58-0.932-0.902-1.633-0.919c-1.486-0.036-2.89,0.497-4.16-0.587
c-0.327-0.279-0.563-0.622-0.713-0.997C1.87,16.911,1.783,16.514,1.783,16.096z M7.095,9.684c0.217-0.251,0.426-0.52,0.638-0.787
c0.577-0.727,1.306-1.351,2.154-1.739c0.856-0.392,1.806-0.437,2.732-0.39c0.861,0.043,1.772,0.171,2.468,0.722
c0.76,0.602,1.104,1.524,1.635,2.304c0.51,0.749,1.429,1.17,2.325,1.17c1.453,0,2.66-1.053,2.899-2.437l0.002-0.014
c0.12-0.588,0.034-1.251-0.311-1.944c-0.037-0.046-0.075-0.091-0.112-0.135c-0.297-0.462-0.719-0.836-1.219-1.073
c-0.684-0.38-1.437-0.243-2.179-0.275c-0.685-0.03-1.443-0.195-2.014-0.59c-0.881-0.609-1.262-1.661-1.879-2.493
c-0.323-0.435-0.904-1.009-1.923-1.09c-0.126-0.016-0.254-0.025-0.384-0.025c-0.593,0-1.145,0.176-1.608,0.478
C9.68,1.784,9.336,2.445,9.048,3.129c-0.311,0.739-0.89,1.252-1.61,1.612C7.01,4.954,6.544,5.092,6.068,5.142
C5.608,5.19,5.179,5.079,4.726,5.079c-0.782,0-1.492,0.305-2.021,0.803C2.234,6.286,2,6.777,1.89,7.233
C1.868,7.314,1.849,7.396,1.833,7.479C1.8,7.655,1.783,7.837,1.783,8.022c0,0.207,0.021,0.409,0.063,0.608
c0.28,1.333,1.463,2.335,2.88,2.335C5.789,10.965,6.479,10.395,7.095,9.684z M10.565,14.646c0.175,0.091,0.36,0.166,0.554,0.221
c0.257,0.074,0.529,0.113,0.809,0.113c1.625,0,2.943-1.318,2.943-2.943c0-0.888-0.393-1.684-1.015-2.224
c-0.434-0.376-0.978-0.628-1.578-0.699c-0.115-0.014-0.231-0.021-0.35-0.021c-1.625,0-2.943,1.318-2.943,2.943
C8.985,13.17,9.625,14.154,10.565,14.646z M19.047,13.216c-1.625,0-2.943,1.318-2.943,2.943c0,1.625,1.318,2.943,2.943,2.943
c1.625,0,2.943-1.318,2.943-2.943C21.99,14.533,20.672,13.216,19.047,13.216z"
      />
    </Icon>
  );
};

export default Logo;
