import React, { ReactElement, useEffect } from "react";
import {
  default as NextApp,
  AppProps,
  AppInitialProps,
  AppContext,
} from "next/app";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import theme from "../theme";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "../lib/apollo-client";
import Router, { useRouter } from "next/router";
import NProgress from "nprogress";
import appWithI18n from "next-translate/appWithI18n";
import useTranslation from "next-translate/useTranslation";
import { AnalyticsProvider, useAnalytics } from "use-analytics";
import analytics from "../lib/analytics";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import "nprogress/nprogress.css";
import "../styles/main.scss";
import "../styles/static-image.scss";
import Head from "next/head";
import { RtlProvider } from "../components";
import i18nConfig from "../i18n";
import pkg from "../package.json";
import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import AuthContextProvider from "../components/AuthContext/AuthContext";
import { POSTHOG_HOST, POSTHOG_KEY } from "../config";

Sentry.init({
  dsn: "https://fb2815c1bab74690bf91efcc0a1c4281@o319853.ingest.sentry.io/6053999",
  release: `www@${pkg.version}`,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: [/mixpanel/],
  // TODO: https://githubmemory.com/repo/getsentry/sentry-docs/issues/3720
  // beforeSend(event) {
  //   if (event.exception) {
  //     Sentry.showReportDialog({
  //       eventId: event.event_id,
  //     });
  //   }
  //   return event;
  // },
});

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(POSTHOG_KEY, {
    api_host: POSTHOG_HOST,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

function Analytics() {
  const router = useRouter();
  const { page } = useAnalytics();

  // Track the initial page load outside of push states
  useEffect(() => page(), [page]);

  // Track route changes when using shallow push
  useEffect(() => {
    router.events.on("routeChangeComplete", () => page());

    return () => {
      router.events.off("routeChangeComplete", () => page());
    };
  }, [router, page]);

  return null;
}

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function App({ Component, pageProps }: AppProps): ReactElement {
  const { lang } = useTranslation();
  const apolloClient = useApollo(pageProps.initialApolloState, lang);
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <PostHogProvider client={posthog}>
      <AnalyticsProvider instance={analytics}>
        <Analytics />
        <ApolloProvider client={apolloClient}>
          <AuthContextProvider>
            <ChakraProvider theme={theme}>
              <RtlProvider>
                <Head>
                  <meta charSet="utf-8" />
                  <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width"
                  />
                </Head>

                <CSSReset />
                <Component {...pageProps} />
              </RtlProvider>
            </ChakraProvider>
          </AuthContextProvider>
        </ApolloProvider>
      </AnalyticsProvider>
    </PostHogProvider>
  );
}

App.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps> => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

// eslint-disable-next-line
// @ts-ignore
export default appWithI18n(App, { ...i18nConfig, skipInitialProps: false });
