import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { SITE_URL, LANGUAGES } from '../config';

export function makePath(path: string, language = 'en'): string {
  const prefix = language === 'en' ? '' : `${language}/`;
  return `${SITE_URL}/${prefix}${path.replace(/^\//, '')}`;
}

export function makeAlternatePath(permalink: string, language = 'en'): string {
  const url = new URL(permalink);
  let path = url.pathname;

  LANGUAGES.forEach((language) => {
    const regex = `^/${language}`;
    path = path.replace(regex, '');
  });

  return makePath(path, language);
}

export function usePersistLocaleCookie(): void {
  const { locale, reload } = useRouter();
  const prevLocaleRef = useRef<string | undefined>(locale);

  useEffect((): void => {
    const date = new Date();
    const expire = 365 * 24 * 3600 * 1000;
    date.setTime(date.getTime() + expire);
    document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`;
    if (
      locale !== prevLocaleRef.current &&
      (locale === 'ar' || prevLocaleRef.current === 'ar')
    ) {
      reload();
    }
    prevLocaleRef.current = locale;
  }, [locale]); // eslint-disable-line
}
