module.exports = {
  locales: ['en', 'es', 'fr', 'pt', 'it', 'sw', 'hi', 'zh', 'ar', 'ru'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'footer', 'error'],
    '/': ['home'],
    'rgx:^/marketplace': ['marketplace', 'library'],
    '/404': ['error'],
    '/features': ['features'],
    '/press': ['press'],
    '/pricing': ['pricing', 'features'],
    '/about/aquaponics': ['about', 'home'],
    '/about/team': ['about'],
    '/clients': ['clients', 'home'],
    '/clients/backyard': ['clients', 'features', 'home'],
    '/clients/commercial': ['clients', 'features', 'home'],
    '/clients/stem': ['clients', 'features', 'home'],
    '/aquaculture': ['aquaculture', 'home'],
    '/hydroponics': ['hydroponics', 'home'],
  },
  loader: false,
  loadLocaleFrom: (lang, ns) =>
    import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default),
};
