import React, { useState, useCallback } from "react";
import {
  Box,
  Heading,
  VStack,
  FormControl,
  FormErrorMessage,
  Icon,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Button,
  FormHelperText,
} from "@chakra-ui/react";
import { useAnalytics } from "use-analytics";
import {
  FaUser,
  FaEnvelope,
  FaLock,
  FaEyeSlash,
  FaEye,
  FaCheckCircle,
  FaChevronRight,
} from "react-icons/fa";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import { get, noop } from "lodash";
import useTranslation from "next-translate/useTranslation";

import { STRIPE_PUBLISHABLE_KEY } from "../../config";

interface RegisterFormProps {
  header?: string;
  description?: string;
  source?: string;
  onSuccess?: () => void;
}

const REGISTER = gql`
  mutation RegisterUser(
    $name: String!
    $email: String!
    $password: String!
    $source: String
    $referral: String
    $ccToken: String
  ) {
    authRegister(
      name: $name
      email: $email
      password: $password
      source: $source
      referral: $referral
      ccToken: $ccToken
    ) {
      user {
        id
        name
        email
      }
    }
  }
`;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const RegisterForm = ({
  source = "www",
  onSuccess = noop,
  header,
  description,
  ...props
}: RegisterFormProps): JSX.Element => {
  const { track } = useAnalytics();
  const { t } = useTranslation("home");
  const { register, watch, handleSubmit, errors, setError } = useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [registerAccount] = useMutation(REGISTER);
  const [registered, setRegistered] = useState<boolean>(false);
  const [registrationError, setRegistrationError] = useState<string | null>(
    null
  );
  const [step, setStep] = useState<number>(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [registering, setRegistering] = useState<boolean>(false);

  const name = watch("name");
  const email = watch("email");
  const password = watch("password");

  const toggleShowPassword = useCallback((): void => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const onSubmit = async (variables: {
    name: string;
    email: string;
    password: string;
  }): Promise<void> => {
    const trimmedName = variables.name.trim();
    if (trimmedName.length === 0) {
      setError("name", {
        message: t("common:errors.required-field"),
        shouldFocus: true,
      });
      return;
    }

    if (step == 0) {
      setStep(1);
      return;
    }

    const referral = get(window, "Rewardful.affiliate.token");
    let token;

    if (!stripe || !elements) {
      return;
    }

    setRegistering(true);

    const card = elements.getElement(CardElement);
    if (!card) {
      return;
    }

    try {
      const result = await stripe.createToken(card);
      if (result.error) {
        throw new Error(result.error.message);
      }
      token = result.token;
    } catch (error: any) {
      setRegistrationError(error.message);
      setRegistering(false);
      setStep(2);
      return;
    }

    try {
      await registerAccount({
        variables: {
          name,
          email,
          password,
          source,
          referral,
          token: token?.id,
        },
        onCompleted: ({ authRegister }) => {
          const user = authRegister?.user;

          if (user) {
            track("Sign Up", {
              id: user.id,
              name,
              email,
              referral,
              source,
            });
          }
        },
      });

      setRegistered(true);
      onSuccess();
    } catch (error: any) {
      const errorMessage = error.graphQLErrors
        ? error.graphQLErrors.map((it: any): string => it.message)
        : error.message;
      setRegistrationError(errorMessage);
      setStep(2);
    }

    setRegistering(false);
  };

  return (
    <Box
      shadow="lg"
      w={{ base: "full", md: 350 }}
      rounded="lg"
      overflow="hidden"
      bg="white"
      {...props}
    >
      {registered ? (
        <Box p={6} alignSelf="center">
          <VStack>
            <Icon fontSize="4xl" as={FaCheckCircle} color="green.600" />
            <Text align="center">{t("registration-success")}</Text>
          </VStack>
        </Box>
      ) : (
        <>
          <Box
            bg="brand.100"
            borderBottom={2}
            borderBottomColor="gray.400"
            textAlign="center"
            py={4}
          >
            <Heading size="md">{header || t("register-header")}</Heading>
          </Box>
          <Box as="form" p={6} onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={3} hidden={step === 1}>
              <Text textAlign="center" fontSize="lg">
                {description || t("register-desc")}
              </Text>
              {!!registrationError && (
                <Text textAlign="center" color="red.500">
                  {registrationError}
                </Text>
              )}
              <FormControl isInvalid={errors.name}>
                <InputGroup size="lg" width="full">
                  <InputLeftElement>
                    <Box as={FaUser} color="gray.300" />
                  </InputLeftElement>
                  <Input
                    name="name"
                    placeholder={t("full-name")}
                    isInvalid={!!errors.name}
                    errorBorderColor="crimson"
                    ref={register({
                      required: t("common:errors.required-field").toString(),
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.email}>
                <InputGroup size="lg" width="full">
                  <InputLeftElement>
                    <Box as={FaEnvelope} color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type="email"
                    name="email"
                    placeholder={t("email-address")}
                    isInvalid={!!errors.email}
                    errorBorderColor="crimson"
                    ref={register({
                      required: t("common:errors.required-field").toString(),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t("common:errors.invalid-email").toString(),
                      },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.password}>
                <InputGroup size="lg" width="full">
                  <InputLeftElement>
                    <Box as={FaLock} color="gray.300" />
                  </InputLeftElement>
                  <Input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder={t("password")}
                    isInvalid={!!errors.password}
                    errorBorderColor="crimson"
                    ref={register({
                      required: t("common:errors.required-field").toString(),
                    })}
                  />
                  <InputRightElement>
                    <Icon
                      as={showPassword ? FaEyeSlash : FaEye}
                      cursor="pointer"
                      color="gray.300"
                      onClick={toggleShowPassword}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                hidden={step !== 0}
                isLoading={registering}
                width="full"
                bg="brand.900"
                bgGradient="linear(to-r, brand.900, brand.700)"
                colorScheme="brand"
                size="lg"
                rightIcon={<FaChevronRight />}
              >
                {t("continue")}
              </Button>
            </VStack>
            <VStack hidden={step === 0} spacing={3}>
              <Text textAlign="center" fontSize="lg" hidden={step !== 1}>
                {t("enter-billing-header")}
              </Text>
              <FormControl>
                <Input as={CardElement} pt={3} />
                <FormHelperText>{t("billing-information-desc")}</FormHelperText>
              </FormControl>
              <Button
                type="submit"
                isLoading={registering}
                width="full"
                bg="brand.900"
                colorScheme="brand"
                size="lg"
              >
                {t("create-account-btn")}
              </Button>
            </VStack>
          </Box>
        </>
      )}
    </Box>
  );
};

const RegisterFormWrapper = (props: RegisterFormProps): JSX.Element => {
  const { lang } = useTranslation();

  return (
    <Elements
      stripe={stripePromise}
      options={{ locale: lang as StripeElementLocale }}
    >
      <RegisterForm {...props} />
    </Elements>
  );
};

export default RegisterFormWrapper;
