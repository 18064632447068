import React, { useState, useMemo } from "react";
import {
  HStack,
  Button,
  Spacer,
  Heading,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from "@chakra-ui/react";
import { Logo } from "..";
import {
  FaChevronRight,
  FaBars,
  FaLanguage,
  FaExclamation,
  FaRegChartBar,
} from "react-icons/fa";
import LogoFull from "../LogoFull";
import UiLink from "../UiLink";
import { UiLinkProps } from "../UiLink/UiLink";
import { LANGUAGES } from "../../config";
import useTranslation from "next-translate/useTranslation";
import setLanguage from "next-translate/setLanguage";
import { useAuthContext } from "../AuthContext/AuthContext";

type NavLinkProps = {
  text: string;
  href: string;
};

const NavLink = ({
  text,
  ...uiLinkProps
}: UiLinkProps & { text: string }): JSX.Element => {
  return (
    <UiLink
      color="blue.900"
      fontWeight="semibold"
      fontSize={14}
      _hover={{ color: "brand.900" }}
      {...uiLinkProps}
    >
      {text}
    </UiLink>
  );
};

const Navbar = (): JSX.Element => {
  const { t, lang } = useTranslation("common");
  const { isAuthenticated } = useAuthContext();
  const signInUrl = `https://my.farmhub.ag?lang=${lang}`;

  const navItems: NavLinkProps[] = useMemo(
    () => [
      {
        text: t("features"),
        href: "/features",
      },
      {
        text: t("our-growers"),
        href: "/clients",
      },
      {
        text: t("marketplace"),
        href: "/marketplace",
      },
      // {
      //   text: t('why-aquaponics'),
      //   href: '/about/aquaponics',
      // },
      {
        text: t("pricing"),
        href: "/pricing",
      },
    ],
    [t]
  );

  const subnavItems: NavLinkProps[] = useMemo(
    () => [
      // {
      //   text: t("download-app"),
      //   href: "https://aquaponics.app/?ref=farmhub.ag",
      // },
      {
        text: t("resources"),
        href: "https://learn.farmhub.ag/resources",
      },
      {
        text: t("blog"),
        href: "https://learn.farmhub.ag/articles",
      },
      {
        text: t("support"),
        href: "https://docs.farmhub.ag",
      },
      {
        text: t("sign-in"),
        href: signInUrl,
      },
    ],
    [t, signInUrl]
  );

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const subnav = (
    <HStack
      bg="white"
      color="gray.800"
      py={2}
      px={{ base: 2, md: 6 }}
      borderBottom="1px solid"
      borderBottomColor="gray.200"
      display={{ base: "none", md: "flex" }}
      fontSize="xs"
      spacing={4}
      fontWeight="normal"
    >
      <HStack>
        <Icon as={FaExclamation} />
        <UiLink
          href="https://learn.farmhub.ag/articles/jonathan-reyes/aquaponics-ai-is-now-farmhub-r-a-new-chapter-in-our-journey/"
          _hover={{ textDecor: "underline" }}
        >
          {t("navbar-cta")}
        </UiLink>
      </HStack>
      <Spacer />
      <Menu>
        <MenuButton>
          <Icon aria-label="Change Language" as={FaLanguage} fontSize="2xl" />
        </MenuButton>
        <MenuList color="gray.900">
          {LANGUAGES.map(({ key, label }) => (
            <MenuItem key={key} onClick={async () => await setLanguage(key)}>
              {label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {subnavItems.map((link) => (
        <UiLink
          key={link.text}
          _hover={{ textDecor: "underline" }}
          href={link.href}
        >
          {link.text}
        </UiLink>
      ))}
    </HStack>
  );

  const brand = (
    <UiLink href="/" aria-label="FarmHub Home">
      <LogoFull h={8} w="auto" color="brand.900" />
    </UiLink>
  );

  const rightCTA = (
    <HStack display={{ base: "none", md: "flex" }}>
      {/* {!isAuthenticated && (
        <Button
          rightIcon={<FaUserLock />}
          size="md"
          colorScheme="brand"
          variant="outline"
          _hover={{ bg: 'brand.900', color: 'white' }}
        >
          {t('login')}
        </Button>
      )} */}

      {isAuthenticated && (
        <UiLink
          href={`https://my.farmhub.ag/projects?ref=farmhub.ag&lang=${lang}`}
        >
          <Button
            rightIcon={<FaRegChartBar />}
            size="md"
            colorScheme="brand"
            variant="outline"
            _hover={{ bg: "brand.900", color: "white" }}
          >
            {t("dashboard")}
          </Button>
        </UiLink>
      )}

      <UiLink
        href={`https://my.farmhub.ag/register?ref=farmhub.ag&lang=${lang}`}
      >
        <Button
          rightIcon={<FaChevronRight />}
          size="md"
          bg="brand.900"
          colorScheme="brand"
        >
          {t("sign-up")}
        </Button>
      </UiLink>
    </HStack>
  );

  const drawer = (
    <Drawer
      isOpen={menuVisible}
      placement="left"
      onClose={() => setMenuVisible(false)}
      size="xs"
    >
      <DrawerOverlay zIndex={9999}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <UiLink href="/">
              <Logo h={6} w="auto" color="brand.900" />
            </UiLink>
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={3} alignItems="start">
              <UiLink href="/">
                <Heading size="md">{t("main")}</Heading>
              </UiLink>
              <Divider />
              {navItems.map((item) => (
                <NavLink key={item.text} my={1} {...item} />
              ))}
              <Divider />
              {subnavItems.map((item) => (
                <NavLink key={item.text} {...item} />
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );

  return (
    <>
      {drawer}

      {subnav}

      <HStack as="nav" spacing={10} py={4} px={{ base: 2, md: 6 }} shadow="md">
        {brand}

        <HStack spacing={6} display={{ base: "none", md: "block" }}>
          {navItems.map((item) => (
            <NavLink key={item.text} {...item} />
          ))}
        </HStack>

        <Spacer />

        {rightCTA}

        <IconButton
          aria-label="Mobile Menu"
          variant="ghost"
          display={{ base: "flex", md: "none" }}
          onClick={() => setMenuVisible(true)}
          color="gray.500"
          icon={<FaBars />}
        />
      </HStack>
    </>
  );
};

export default Navbar;
