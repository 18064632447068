import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import customerIOPlugin from '@analytics/customerio';
import {
  CUSTOMER_IO_API_KEY,
  CUSTOMER_IO_SITE_ID,
  GOOGLE_TAG_MANAGER_ID,
  MIXPANEL_TOKEN,
} from '../config';

const plugins =
  process.env.NODE_ENV === 'production'
    ? [
      mixpanelPlugin({
        token: MIXPANEL_TOKEN,
      }),
      googleTagManager({
        containerId: GOOGLE_TAG_MANAGER_ID,
      }),
      customerIOPlugin({
        siteId: CUSTOMER_IO_SITE_ID,
        apiKey: CUSTOMER_IO_API_KEY,
      }),
    ]
    : [];

const analytics = Analytics({
  app: 'www',
  plugins,
});

export default analytics;
