import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  ButtonProps,
} from '@chakra-ui/react';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';

type NewsletterButtonProps = ButtonProps;

const NewsletterButton = (props: NewsletterButtonProps): JSX.Element => {
  const { t } = useTranslation('common');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} {...props}>
        {t('sign-up-for-newsletter')}
      </Button>

      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody display="flex" p={0}>
            <Box
              as="iframe"
              width="full"
              minH="70vh"
              src="https://df4825ae.sibforms.com/serve/MUIEAIQtd2-vqgx70QkUCGoXDjSsXg2bEHH_0vYNtk333C1h5jwX2ZrbeFVcvx9FGcBt0Rl6q74xx6F6-UxE51h0PjgPrmAPb94jaE1ukL10FgABAKfCJ-CQlNKYjySG6EM51TOCSgfzPfD5Jyfo4w31EYtBOEe65i8K0J0NBnlaLkYdwjS-F0JvS8prqEZ1CkCn3r4Ics747SI8"
              scrolling="auto"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewsletterButton;
