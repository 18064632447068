import React, { useEffect, useState } from 'react';
import { useNumberInput, HStack, Input, IconButton } from '@chakra-ui/react';
import { FaMinus, FaPlus } from 'react-icons/fa';

type QuantitySelectorProps = {
  onChange?: (value: number) => void;
};

const QuantitySelector = ({ onChange }: QuantitySelectorProps): JSX.Element => {
  const {
    value,
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step: 1,
    defaultValue: 1,
    min: 0,
    precision: 0,
  });

  const [previousValue, setPreviousValue] = useState<number>();

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  useEffect(() => {
    const parsedValue = parseInt(value.toString(), 10);
    if (previousValue !== parsedValue && onChange) {
      onChange(parsedValue);
      setPreviousValue(parsedValue);
    }
  }, [value, onChange, setPreviousValue, previousValue]);

  return (
    <HStack maxW={60}>
      <IconButton icon={<FaMinus />} {...dec} aria-label="decrement" />
      <Input {...input} fontSize="lg" textAlign="center" bg="white" />
      <IconButton icon={<FaPlus />} {...inc} aria-label="increment" />
    </HStack>
  );
};

export default QuantitySelector;
