import { useMemo } from 'react';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  Operation,
  NextLink,
  from,
} from '@apollo/client';
import { GRAPHQL_ENDPOINT } from '../config';
import { getAuthorzationHeader } from './auth';

let apolloClient: any;
let language: string | undefined;

const createApolloClient = (
  language: string | undefined
): ApolloClient<any> => {
  const languageLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      operation.setContext({
        headers: {
          'Accept-Language': language,
        },
      });

      return forward(operation);
    }
  );

  const authorizationLink = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      const ctx = operation.getContext();

      operation.setContext({
        headers: {
          ...ctx.headers,
          authorization: getAuthorzationHeader(),
        },
      });

      return forward(operation);
    }
  );

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      languageLink,
      authorizationLink,
      new HttpLink({ uri: GRAPHQL_ENDPOINT }),
    ]),
    cache: new InMemoryCache(),
  });
};

export function initializeApollo(
  initialState: any = null,
  locale?: string
): ApolloClient<any> {
  let _apolloClient: ApolloClient<any> = apolloClient;
  if (locale !== language) {
    language = locale;
    _apolloClient = createApolloClient(language);
  } else if (!_apolloClient) {
    _apolloClient = createApolloClient(language);
  }

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState !== null) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({
      ...existingCache,
      ...initialState,
    });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

/* eslint-disable-next-line */
export function useApollo(
  /* eslint-disable-next-line */
  initialState: any,
  locale: string
): ApolloClient<any> {
  return useMemo(
    () => initializeApollo(initialState, locale),
    [initialState, locale]
  );
}
