import * as React from 'react';
import { Box, Heading, Container, Text, Button, Link } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';

type Props = {
  title?: string;
  description?: string;
  ctaText?: string;
  ctaUrl?: string;
};

const BannerCta = ({
  title,
  description,
  ctaText,
  ctaUrl,
}: Props): JSX.Element => (
  <Box bgGradient="linear(to-r, brand.900, brand.700)">
    <Container maxW="3xl" textAlign="center" py={16}>
      {title && (
        <Heading as="h3" size="lg" color="white" fontWeight="extrabold">
          {title}
        </Heading>
      )}

      {description && (
        <Text color="white" mt={3}>
          {description}
        </Text>
      )}

      {ctaText && ctaUrl && (
        <Link href={ctaUrl} _hover={{}}>
          <Button
            variant="outline"
            fontWeight="bold"
            borderWidth={3}
            color="white"
            mt={8}
            size="lg"
            rightIcon={<FaChevronRight />}
            _hover={{ color: 'gray.800', bg: 'white', borderColor: 'white' }}
          >
            {ctaText}
          </Button>
        </Link>
      )}
    </Container>
  </Box>
);

export default BannerCta;
