import { Button, ButtonProps, useToast } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import Product from '../../models/product';
import { gql, useMutation } from '@apollo/client';

const CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession(
    $products: [CheckoutSessionProductAttributes!]!
  ) {
    checkoutSessionCreate(products: $products) {
      checkoutUrl
    }
  }
`;

type BuyButtonProps = {
  products: Product[];
} & ButtonProps;

const BuyButton = ({
  products = [],
  children,
  ...props
}: BuyButtonProps): JSX.Element => {
  const toast = useToast();
  const [getCheckoutUrl, { loading }] = useMutation(CHECKOUT_SESSION);

  const goToCheckout = useCallback(async () => {
    try {
      const { data } = await getCheckoutUrl({
        variables: {
          products: products.map((product) => ({
            id: product.id,
            quantity: 1,
          })),
        },
      });

      window.location = data?.checkoutSessionCreate?.checkoutUrl;
    } catch (e: any) {
      if (e.graphQLErrors) {
        toast({
          title: e.graphQLErrors[0].message,
          status: 'warning',
        });
      }
    }
  }, [products, toast, getCheckoutUrl]);

  return (
    <Button onClick={() => goToCheckout()} isLoading={loading} {...props}>
      {children}
    </Button>
  );
};

export default BuyButton;
